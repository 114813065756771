<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section logo-section">
        <ResponsiveLogo/>
        <p class="tagline">Transformez l'organisation de votre prochain voyage en une balade de santé avec SmartVacances</p>
      </div>
      <div class="footer-section links-section">
<!--        <div class="links-column">-->
<!--          <h4>Légal</h4>-->
<!--          <ul>-->
<!--            <li><a href="/terms">Termes et conditions</a></li>-->
<!--            <li><a href="/privacy">Protection de la vie privée</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="links-column">-->
<!--          <h4>Support</h4>-->
<!--          <ul>-->
<!--            <li><a href="/contact">Nous contacter</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div class="links-column">-->
<!--          <h4>Itineraries</h4>-->
<!--          <ul>-->
<!--            <li><a href="/community-trips">Community Trips</a></li>-->
<!--            <li><a href="/find-destinations">Find Destinations</a></li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </div>
    <div class="footer-bottom">
      <p>© 2024 SmartVacances. Tout droit réservé</p>
    </div>
  </footer>
</template>

<script>
import ResponsiveLogo from "@/components/Base/ResponsiveLogo.vue";

export default {
  name: 'FooterComponent',
  components: {ResponsiveLogo},
  props: {
    logo: {
      type: String,
      default: 'path-to-your-logo.png'
    }
  }
};
</script>

<style scoped>
.footer {
  text-align: -webkit-center;
  background-color: #fff;
  padding: 20px 5px;
  border-top: 1px solid #eaeaea;
  margin-top: 50px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-section {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

.tagline {
  color: #666;
  font-size: 14px;
  margin: 0;
}

.links-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.links-column {
  text-align: left;
}

.links-column h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.links-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.links-column li {
  margin-bottom: 5px;
}

.links-column a {
  color: #007bff;
  text-decoration: none;
}

.links-column a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 5px 0;
}

.create-trip-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}

.create-trip-btn:hover {
  background-color: #218838;
}

@media (min-width: 768px) {
  .footer {
    margin-top: 70px;
  }
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .logo-section {
    text-align: left;
    margin-bottom: 0;
  }

  .tagline {
    text-align: left;
  }

  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .create-trip-btn {
    margin-top: 0;
  }
}
</style>

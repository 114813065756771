<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import 'variables.css';

@font-face {
  font-family: 'aquatico';
  src: url('assets/fonts/Aquatico-Regular.otf') format('truetype');
}

body {
  margin: 8px 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.error-message {
  color: var(--errorColor);
}

.title-with-separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.title-with-separator::before,
.title-with-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--primaryColor);
  margin: 0 10px;
}

a {
  color: initial;
  text-decoration: none;
}

#smartvacances-logo {
  width: 300px;
  border-radius: 5px;
}

.centered-paragraph {
  max-width: 330px;
}

.create-destination-suggestion-btn {
  background-color: var(--primaryColor);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 17px;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid var(--primaryColor);
}

.create-destination-suggestion-btn:hover {
  opacity: 0.8;
}

@media (min-width: 1024px) {
  .centered-paragraph {
    max-width: 650px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  #app {
    width: 1200px;
    margin: 0 auto;
  }
}
</style>

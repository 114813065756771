<template>
  <ViewLayout>

    <div v-if="destinationSpecificationLoading" ref="preloader" class="preloader" id="preloader">
      <div class="preloader-container">
        <h1>Encore un peu de patience, nous générons vos vacances !</h1>
        <h1 v-if="destinationName">{{ destinationName }}</h1>
        <div class="preloader__circle"></div>
      </div>
    </div>

    <span v-if="errorMsg" class="error-message">{{ errorMsg }}</span>

    <div v-if="this.destination" class="destination-container">
      <h2>
        {{ this.destination.shortDescription }}
      </h2>
      <div class="destination-summary">
        <div class="destination-summary-info">
          <div class="destination-summary-date-container">
            <label>Votre séjour du </label>
            <span class="destination-summary-date">{{ this.formatDate(this.destination.hotel.checkIn) }}</span>
            au
            <span class="destination-summary-date">{{ this.formatDate(this.destination.hotel.checkOut) }}</span>
          </div>
          <div v-if="this.destination.distance > 0">
            À <strong>{{ this.destination.distance }} km</strong> de chez vous
          </div>
          <VehicleImage v-for="(vehicle, index) in this.destination.vehicles" :key="index" :vehicleName="vehicle"/>
          <div>
            De <strong>{{ this.destination.minTemperature }}°C</strong> à <strong>{{ this.destination.maxTemperature }}°C</strong>
          </div>
          <WeatherComponent :minTemperature="this.destination.minTemperature" :maxTemperature="this.destination.maxTemperature"/>
          <p class="centered-paragraph">
            {{ this.destination.description }}
          </p>
          <a class="destination-city-link" :href="destination.website" target="_blank" rel="noopener noreferrer">
            Voir le site de la ville
          </a>
        </div>

        <HotelCard :hotel="this.destination.hotel"/>
      </div>

      <h2 class="title-with-separator">Votre planning</h2>

      <div class="planning-container">
        <ActivityCard v-for="(activity, index) in this.destination.planning" :key="index" :activity="activity" />
      </div>
    </div>

  </ViewLayout>
</template>

<script>
import ViewLayout from "@/ViewLayout.vue";
import HotelCard from "@/components/Cards/HotelCard.vue";
import ActivityCard from "@/components/Cards/ActivityCard.vue";
import VehicleImage from "@/components/VehicleImage.vue";
import WeatherComponent from "@/components/Base/WeatherComponent.vue";
import smartVacancesApiClient from "@/SmartVacancesApiClient";

export default {
  name: 'SpecifiedDestinationOverview',
  components: {
    WeatherComponent,
    VehicleImage,
    ActivityCard,
    HotelCard,
    ViewLayout
  },
  props: {
    destinationName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      destinationSpecificationLoading: true,
      errorMsg: null,
      destination: null,
      destinationWeatherIcon: null,
    };
  },
  methods: {
    async fetchDestinationSpecification(destinationId) {
      try {
        const response = await smartVacancesApiClient.get(`v1/destinations/${destinationId}/specification`);

        this.destinationSpecificationLoading = false;
        this.destination = response.data.data;
      } catch (error) {
        let errorMsg = error.response.data.error ?? 'Une erreur est survenue lors de la spécification. Ré-essayez ultérieurement !';
        if (errorMsg === 'Error while specifying destination') {
          errorMsg = 'Une erreur est survenue lors de la spécification de la destination. Elle est indépendante de votre requête. Nous vous remercions de bien vouloir l\'effectuer de nouveau ultérieurement, et nous excusons pour le désagrément.'
        }

        this.errorMsg = errorMsg;

        console.error('Erreur lors de la récupération des données:', error);
        this.destinationSpecificationLoading = false;
      }
    },
    formatDate(dateStr) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const date = new Date(dateStr);

      return date.toLocaleDateString('fr-FR', options);
    },
  },
  mounted() {
    this.fetchDestinationSpecification(this.$route.params.destinationId);
  }
}
</script>

<style>

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .25s ease;
}

.preloader-container {
  text-align: center;
  text-align: -webkit-center;
}

.preloader__circle {
  border-top: 2px solid #fff;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  animation: preloader-spin 1s linear infinite;
}

@keyframes preloader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.planning-container {
  display: block;
  padding: 0 20px 20px;
}

.destination-summary {
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.destination-summary-info {
  margin-bottom: 20px;
}

.destination-summary-date {
  background-color: var(--primaryColor);
  color: white;
  font-weight: 800;
  padding: 1px 3px;
  border-radius: 5px;
  text-shadow: 0.5px 0.5px black;
}

.destination-summary-date-container {
  margin: 10px;
}

.destination-city-link {
  text-decoration: underline;
  color: unset;
  letter-spacing: 1px;
}

.destination-city-link:hover {
  color: var(--primaryColor);
}

.activity-content .address {
  font-size: 13px;
  display: block;
  margin: 0;
}

@media (max-width: 768px) {
  .destination-container {
    text-align: -webkit-center;
  }
}

@media (min-width: 1024px) {
  .destination-summary {
    display: flex;
    flex-direction: row;
  }

  .destination-summary > * {
    width: 50%;
  }

  .planning-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
}
</style>

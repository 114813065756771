<template>
  <router-link :to="{ name: 'Homepage' }">
    <div class="logo">
      <label>Smart Vacances</label>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ResponsiveLogo',
};
</script>

<style scoped>
.logo {
  width: 175px;
  height: 35px;
  background-color: var(--primaryColor);
  font-family: 'aquatico';
  color: white;
  font-weight: 800;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo label {
  text-shadow: 0.5px 0.5px black;
  cursor: pointer;
}

@media (min-width: 768px) {
  .logo {
    width: 300px;
    height: 50px;
    font-size: 27px;
  }
}
</style>

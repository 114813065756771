<template>
  <ViewLayout>

    <div id="homepage-container">
      <div class="intro-container">
        <h1 class="title">Vos prochaines vacances, <label class="primary-color-label">sans effort</label></h1>
        <p class="centered-paragraph">
          Générez des suggestions de destinations qui vous ressemblent, transformez-les en vos prochaines vacances de rêve en en modifiant chaque aspect.
          Conçu pour les voyageurs qui veulent profiter de leurs vacances sans passer des heures à les organiser.
        </p>

        <router-link :to="{ name: 'GenerateDestinationsSuggestion'}">
          <button class="create-destination-suggestion-btn" onclick="">Créer un nouveau voyage</button>
        </router-link>
      </div>

      <img
          :src="require('@/assets/holiday-banner.jpeg')"
          alt="Travel"
          class="banner-image"
      >

      <div class="section">
        <div class="content-container">
          <div class="text-section">
            <h2 class="subtitle">
              Votre voyage <label class="primary-color-label">boosté par l'IA</label>
            </h2>
            <p class="centered-paragraph">
              Nous utilisons l'intelligence artificielle pour vous proposer des destinations qui correspondent à vos envies, et aux moindres détails que vous nous transmettez.
            </p>
          </div>
          <div class="image-section">
            <img
                :src="require('@/assets/beach.png')"
                alt="beach"
                class="icon"
            >
            <img
                :src="require('@/assets/volley-ball.png')"
                alt="volley-ball"
                class="smaller-icon"
            >
          </div>
        </div>
      </div>

      <div class="section">
        <div class="content-container">
          <div class="image-section">
            <img
                :src="require('@/assets/map.png')"
                alt="map"
                class="icon"
            >
          </div>
          <div class="text-section">
            <h2 class="subtitle">
              Tout en <label class="primary-color-label">un</label> !
            </h2>
            <p class="centered-paragraph">
              Dites adieu au stress de la planification !
              Générez des suggestions de destinations, peaufinez-les à votre goût puis réservez votre hébergement, vos activités et vos transports en un seul endroit.
            </p>
          </div>
        </div>
      </div>

      <div v-show="this.homepageDestinations">
        <h2 class="subtitle title-with-separator">Consultez des destinations déjà générées par d'autres utilisateurs</h2>

        <SliderDestinationsList :destinations="this.homepageDestinations" :is-slider="true"/>
      </div>

<!--      <div class="section">-->
<!--        <div class="content-container">-->
<!--          <h2 class="subtitle">-->
<!--            Plus de <label class="primary-color-label">307</label> destinations et <label class="primary-color-label">452</label> activités à découvrir-->
<!--          </h2>-->
<!--          <p class="centered-paragraph">-->
<!--            En plus de pouvoir générer vos propres idées à l'oral ou à l'écrit, vous pouvez piocher dans une vaste bibliothèque de destinations et d'activités pour enrichir votre voyage.-->
<!--          </p>-->
<!--        </div>-->
<!--      </div>-->
      <div v-show="showStickyButton" class="sticky-footer">
        <router-link :to="{ name: 'GenerateDestinationsSuggestion' }">
          <button class="create-destination-suggestion-btn">Créer un nouveau voyage</button>
        </router-link>
      </div>
    </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from '@/ViewLayout.vue'
import SliderDestinationsList from "@/components/SliderDestinationsList.vue";
import GenerateDestinationsSuggestion from "@/views/GenerateDestinationsSuggestion.vue";
import smartVacancesApiClient from "@/SmartVacancesApiClient";

export default {
  name: 'HomepageView',
  computed: {
    GenerateDestinationsSuggestion() {
      return GenerateDestinationsSuggestion;
    }
  },
  components: {
    SliderDestinationsList,
    ViewLayout,
  },
  data() {
    return {
      modalOpen: true,
      homepageDestinations: undefined,
      showStickyButton: false,
    };
  },
  methods: {
    async getHomepageDestinations() {
      try {
        const response = await smartVacancesApiClient.get('v1/destinations/sample');

        this.homepageDestinations = response.data.data.destinations;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getHomepageDestinations();
  },
};
</script>

<style scoped>
.primary-color-label {
  color: var(--primaryColor);
}

.icon {
  width: 100px;
}

.smaller-icon {
  width: 85px;
  height: 85px;
}

#homepage-container {
  margin: 8px;
  text-align: center;
}

.intro-container {
  text-align: center;
  text-align: -webkit-center;
}

.title {
  font-size: 42px;
  line-height: 40px;
  margin-top: 50px;
  font-weight: 900;
  letter-spacing: -2px;
}

.banner-image {
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
}

.subtitle {
  font-size: 28px;
  line-height: 30px;
  margin-top: 20px;
  font-weight: 900;
  letter-spacing: -1px;
  padding-top: 5px;
}

.section {
  margin-bottom: 50px;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-section {
  margin-bottom: 20px;
}

.image-section {
  display: flex;
  justify-content: center;
  gap: 10px;
}

@media (min-width: 1024px) {
  #homepage-container {
    .intro-container {
      .title {
        font-size: 60px;
        line-height: 30px;
      }
    }

    .banner-image {
      width: 700px;
    }

    .subtitle {
      font-size: 38px;
    }

    .content-container {
      flex-direction: row;
      text-align: left;
    }

    .text-section {
      width: 60%;
      margin-bottom: 0;
    }

    .image-section {
      width: 40%;
      justify-content: center;
    }

    .section-title {
      font-size: 34px;
    }
  }
}
</style>

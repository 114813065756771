<template>
  <ul :class="['destinations-slider-container', { 'is-slider': isMobile }]">
    <li v-for="destination in destinations" :key="destination.id" class="destination-slider-item">
      <router-link :to="{ name: 'SpecifiedDestinationOverview', params: { destinationId: destination.id }, props: { destinationName: destination.city } }">
        <DestinationCard :destination="destination"/>
      </router-link>
    </li>
  </ul>
</template>

<script>
import DestinationCard from "@/components/Cards/DestinationCard.vue";

export default {
  name: 'SliderDestinationsList',
  props: {
    destinations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < 1024;
    },
  },
  components: {
    DestinationCard
  }
}
</script>

<style scoped>
.destinations-slider-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  gap: 10px;
}

.destination-slider-item {
  flex: 1 1 calc(50% - 20px);
  padding: 5px;
  margin: 10px 0;
  border-radius: 5px;
}

@media (min-width: 1024px) {
  .destination-slider-item {
    flex: 1 1 calc(50% - 20px);
    margin: 10px 5px;
  }

  .destinations-slider-container {
    display: initial;
  }
}

.is-slider {
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 0;
}

.is-slider .destination-slider-item {
  flex: 0 0 100%;
  margin: 10px 5px;
}

@media (min-width: 768px) {
  .is-slider .destination-slider-item {
    flex: 0 0 calc(50% - 10px);
  }
}


@media (min-width: 1024px) {
  .destination-slider-item {
    margin: 15px 0;
    padding: 0;
  }
}

</style>

<template>
  <ul class="destinations-container">
    <li v-for="destination in destinations" :key="destination.id" class="destination-item">
      <router-link :to="{ name: 'SpecifiedDestinationOverview', params: { destinationId: destination.id }, props: { destinationName: destination.city } }">
        <DestinationCard :destination="destination"/>
      </router-link>
    </li>
  </ul>
</template>

<script>
import DestinationCard from "@/components/Cards/DestinationCard.vue";

export default {
  name: 'DestinationsList',
  props: {
    destinations: {
      type: Array,
      required: true,
    },
  },
  components: {
    DestinationCard
  }
}
</script>

<style scoped>
.destinations-container {
  padding: 0;
  list-style: none;
}

.destination-item {
  padding: 5px;
  margin: 0 5px;
  list-style: none;
}

@media (min-width: 1024px) {
  .destination-item {
    margin: 15px 0;
    padding: 0;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import store from './store';
import Homepage from "@/views/Homepage.vue";
import SpecifiedDestinationOverview from '@/views/SpecifiedDestinationOverview.vue';
import GenerateDestinationsSuggestion from "@/views/GenerateDestinationsSuggestion.vue";
import UserProfile from "@/views/UserProfile.vue";

const routes = [
    {
        path: '/',
        component: Homepage,
        name: 'Homepage'
    },
    {
        path: '/generate',
        component: GenerateDestinationsSuggestion,
        name: 'GenerateDestinationsSuggestion'
    },
    {
        path: '/destination/:destinationId',
        component: SpecifiedDestinationOverview,
        name: 'SpecifiedDestinationOverview'
    },
    {
        path: '/my-profile',
        component: UserProfile,
        name: 'UserProfile',
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
        return next({ name: 'Homepage' });
    }

    next();
});

export default router;

<template>
  <div>
    <HeaderSticky/>
    <slot></slot>

    <FooterComponent/>
  </div>
</template>

<script>
import HeaderSticky from '@/components/HeaderSticky.vue'
import FooterComponent from '@/components/Base/Footer.vue'

export default {
  components: {
    HeaderSticky,
    FooterComponent
  }
}
</script>

<style>
</style>

// src/axios.js
import axios from 'axios';
import store from './store';

const smartVacancesApiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

smartVacancesApiClient.interceptors.request.use(config => {
    const token = store.getters.token;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default smartVacancesApiClient;

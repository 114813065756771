<template>
  <div class="destination-card">
    <div class="image-container">
      <img :src="destination.cityPictureUrl" alt="Destination city image" class="destination-city-image"/>
      <div class="overlay">
        <div><strong>{{ destination.city }} - {{ destination.country }}</strong></div>
        <div class="overlay-content">

          <div class="overlay-destination-details">
            <label>{{ destination.minTemperature }}°C à {{ destination.maxTemperature }}°C</label>
            <WeatherComponent :minTemperature="destination.minTemperature" :maxTemperature="destination.maxTemperature"/>
          </div>
          <div class="overlay-destination-details">
            <label v-if="destination.distance">{{ destination.distance }} km</label>
            <img
                v-for="(vehicle, index) in destination.vehicles" :key="index"
                class="vehicle-logo"
                :src="require('@/assets/vehicles/v2/' + vehicle + '.png')"
                alt="Logo du véhicule"
            >
          </div>
        </div>
      </div>
    </div>
    <p>{{ destination.description }}</p>
  </div>
</template>

<script>
import WeatherComponent from "@/components/Base/WeatherComponent.vue";

export default {
  name: 'DestinationCard',
  components: { WeatherComponent },
  props: {
    destination: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.destination-card {
  border: 1px solid var(--primaryColor);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0;
  margin: 10px 0;
  overflow: hidden;
  position: relative;

  p {
    margin: 15px 10px;
  }
}
.destination-card:hover {

}

.image-container {
  position: relative;
}

.destination-city-image {
  width: 100%;
  object-fit: cover;
  height: 220px;
}

.overlay {
  position: absolute;
  bottom: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 100%;
  padding: 5px;
  text-align: center;
}

.overlay-content {
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  gap: 15px;
}

.overlay-destination-details {
  align-items: center;
  display: inline-flex;
  font-size: 13px;

  label {
    margin-right: 7px;
  }

  img {
    width: 20px !important;
  }
}

.vehicle-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vehicle-logo {
  width: 30px !important;
}

.details-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}

.left-details {
  display: flex;
  flex-direction: column;
}

.vehicles {
  display: flex;
}

.vehicle-logo {
  width: 50px;
}

.destination-city-link {
  text-decoration: underline;
  color: unset;
  letter-spacing: 1px;
}

.destination-city-link:hover {
  color: var(--primaryColor);
}

@media (min-width: 768px) {
  .details-container {
    justify-content: center;
  }

  .destination-city-image {
    height: 350px;
  }

  .overlay {
    font-size: 24px;
  }

  .overlay-destination-details {
    font-size: 17px;

    img {
      width: 30px !important;
    }
  }
}
</style>

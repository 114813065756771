<template>
  <ViewLayout>
  <div class="profile-container">

    <div v-if="this.tabs">
      <h1 class="title-with-separator">
        Vos destinations
      </h1>
      <TabSelector :tabs="tabs">
        <template v-slot:tab-0>
          <div v-if="this.userDestinationsOverview?.userDestinationsSuggestions">
            <div
                v-for="destinationSuggestion in this.userDestinationsOverview.userDestinationsSuggestions.destinationsSuggestions"
                :key="destinationSuggestion.id"
            >
              <div>
                <h3>Pour les critères :</h3>
                <p class="criteria">{{ destinationSuggestion.criteria }}</p>
              </div>

              <DestinationsList :destinations="destinationSuggestion.destinations"/>
            </div>
          </div>
          <div v-else>
            <p>Vous n'avez pas encore de suggestions de destination</p>

            <router-link :to="{ name: 'GenerateDestinationsSuggestion' }">
              <button class="create-destination-suggestion-btn">Créer un nouveau voyage</button>
            </router-link>
          </div>
        </template>
        <template v-slot:tab-1>
          <div>
            <h3>Second Tab Content</h3>
          </div>
        </template>
        <template v-slot:tab-2>
          <div>
            <h3>Third Tab Content</h3>
          </div>
        </template>
      </TabSelector>
    </div>
    <div v-else>
      <p>Chargement...</p>
    </div>

<!--    <h1 class="title-with-separator">-->
<!--      Vos informations personnelles-->
<!--    </h1>-->

  </div>
  </ViewLayout>
</template>

<script>
import ViewLayout from "@/ViewLayout.vue";
import TabSelector from "@/components/Base/TabSelector.vue";
import smartVacancesApiClient from "@/SmartVacancesApiClient";
import DestinationsList from "@/components/DestinationsList.vue";

export default {
  name: 'UserProfile',
  components: {DestinationsList, TabSelector, ViewLayout},
  data() {
    return {
      tabs: [
        {
          label: 'Vos suggestions de destination',
          count: 0,
          content: '1',
        },
        // {
        //   label: 'Vos destinations',
        //   count: 3,
        //   content: '2',
        // },
        // {
        //   label: 'Categ 3',
        //   count: 3,
        //   content: '3',
        // },
      ],
      userDestinationsOverview: undefined,
    };
  },
  methods: {
    async fetchTabsData() {
      try {
        const response = await smartVacancesApiClient.get('v1/user/destination-overview');

        console.log('userDestinationsOverview', response.data.data);
        this.userDestinationsOverview = response.data.data;
        this.tabs[0].count = this.userDestinationsOverview.userDestinationsSuggestions.count;
      } catch (error) {
        console.error('Erreur lors du chargement des données :', error);
      }
    },
  },
  mounted() {
    this.fetchTabsData();
  },
};
</script>

<style scoped>
.profile-container {
  margin: 8px;
  text-align: center;
}

.criteria {
  font-style: italic;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import vue3GoogleLogin from 'vue3-google-login'
import store from './store';

createApp(App)
    .use(router)
    .use(store)
    .use(vue3GoogleLogin, {
        clientId: '497968535728-krhp8ej3p18f7gb3g4ggg27cbo69sti4.apps.googleusercontent.com'
    })
    .mount('#app');

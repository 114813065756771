<template>
  <img
    class="vehicle-logo"
    :src="require('@/assets/vehicles/v2/' + this.vehicleName + '.png')"
    alt="Logo du véhicule"
  >
</template>

<script>
export default {
  name: "VehicleImage",
  props: {
    vehicleName: {
      type: String,
      required: true,
    }
  },
};
</script>

<style scoped>
.vehicle-logo {
  width: 50px;
}
</style>
